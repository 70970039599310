import React from "react";
import Layout from "../components/layout";
import { FaLinkedin } from "react-icons/fa";
import { MdMail } from "react-icons/md";
export default function Contact() {
  return (
    <Layout>
      <p style={{'grid-column': 'span 2'}}>
        If you'd like more information about the services that we can provide please get in touch by email.
        <div style={{ display: "flex", alignItems: "center" }}>
          <MdMail size={"1.5rem"} />
          <span>
            <a href="mailto:richard@miskinsoftware.com">richard@miskinsoftware.com</a>
          </span>
        </div>
      </p>
    </Layout>
  );
}
